// QuestionsContext.tsx
import React, { createContext, useState, ReactNode } from "react";

export interface InputElementQuestion {
  id: number;
  text: string;
  order: number;
  type: "text" | "file";
}

interface QuestionsProviderProps {
  children: ReactNode;
}

type DemoContextType = {
  questions: InputElementQuestion[];
  setQuestions: React.Dispatch<React.SetStateAction<InputElementQuestion[]>>;
};

export const QuestionsContext = createContext<DemoContextType>({
  questions: [],
  setQuestions: () => {},
});

export const DemoContextProvider: React.FC<QuestionsProviderProps> = ({
  children,
}) => {
  const [questions, setQuestions] = useState<InputElementQuestion[]>([]);

  return (
    <QuestionsContext.Provider value={{ questions, setQuestions }}>
      {children}
    </QuestionsContext.Provider>
  );
};
