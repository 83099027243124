import React, { useContext, useRef, useState } from "react";
import Footer from "./../components/Footer";
import Settings from "./../Settings";
import {
  Bot,
  Info,
  Send,
  WandSparkles,
  ArrowRight,
  CirclePlus,
  CircleX,
  Blend,
} from "lucide-react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import DemoMarkdownEditor from "./../components/DemoMarkdownEditor";
import { QuestionsContext } from "./DemoContextProvider";
import { Link, useNavigate } from "react-router-dom";

interface FollowUp {
  id: number;
  question: string;
  answer: string;
}

interface QuestionAnswer {
  questionId: number;
  answer: string;
  followUps: FollowUp[];
}

interface InterviewSettingsLLM {
  askFollowUpQuestions: boolean;
  tonality: number; // 1 = Friendly, 2 = Formal, etc.
}

interface InputElementQuestion {
  id: number;
  text: string;
  order: number;
  type: "text" | "file";
}

const DemoCreateInterview: React.FC = () => {
  const [jobAd, setJobAd] = useState<string>("");
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState<boolean>(false);
  const [isLoadingJobAd, setIsLoadingJobAd] = useState<boolean>(false);

  const { setQuestions } = useContext(QuestionsContext);
  // We now only have ONE list for interview questions
  const [localQuestions, setLocalQuestions] = useState<InputElementQuestion[]>([]);

  // Whether to show the "Try out the interview" Q&A flow (true)
  // or the draggable list of questions (false).
  const [showInterview, setShowInterview] = useState<boolean>(false);

  // ------------------------------------------------------------------
  //  Q&A Flow State
  // ------------------------------------------------------------------
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [followUpQuestion, setFollowUpQuestion] = useState("");
  const [isSendingAnswer, setIsSendingAnswer] = useState(false);
  const [isInterviewCompleted, setIsInterviewCompleted] = useState(false);
  const [questionsAnswers, setQuestionsAnswers] = useState<QuestionAnswer[]>([]);
  const [settingsLLM, setSettingsLLM] = useState<InterviewSettingsLLM>({
    askFollowUpQuestions: true,
    tonality: 1,
  });

  const [jobTags, setJobTags] = useState<string[]>([]);
  const [tagInput, setTagInput] = useState<string>("");

  const userAnswerRef = useRef<HTMLTextAreaElement>(null);

  const navigate = useNavigate();

  // ------------------------------------------------------------------
  //  Parse suggestions directly into the "questions" list
  // ------------------------------------------------------------------
  const parseSuggestions = (text: string): InputElementQuestion[] => {
    const lines = text.split("\n");
    const items: InputElementQuestion[] = [];

    lines.forEach((line, index) => {
      const trimmed = line.trim();
      if (trimmed.startsWith("- ")) {
        items.push({
          id: Date.now() + index,
          text: trimmed.slice(2).trim(),
          order: items.length,
          type: "text",
        });
      }
    });
    return items;
  };

  const suggestQuestions = async () => {
    if (!jobAd.trim()) {
      alert("Please enter a job ad first!");
      return;
    }
    setIsLoadingSuggestions(true);

    try {
      const response = await fetch(`${Settings.API_URL}/demo/suggest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          promptId: 0,
          text: jobAd,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      const rawText = responseData.choices?.[0]?.message?.content || "";

      // Parse out suggestions from the text
      const parsed = parseSuggestions(rawText);

      // <--- Instead of storing in 'suggestions', we place them directly into 'questions' --->
      setLocalQuestions(() => {
        // If you want to *append* suggestions, you could do:
        //   return [...prev, ...parsed].map((q, i) => ({ ...q, order: i }));
        // If you want to *replace* the list, do:
        return parsed.map((item, i) => ({ ...item, order: i }));
      });
    } catch (error) {
      console.error("Error while fetching suggestions:", error);
      alert("An error occurred while fetching suggestions");
    } finally {
      setIsLoadingSuggestions(false);
    }
  };

  const suggestJobAd = async () => {
    setIsLoadingJobAd(true);

    try {
      const response = await fetch(`${Settings.API_URL}/demo/suggest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          promptId: 1,
          text: jobTags,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      const rawText = responseData.choices?.[0]?.message?.content || "";

      setJobAd(rawText);
    } catch (error) {
      console.error("Error while fetching job ad:", error);
      alert("An error occurred while fetching job ad");
    } finally {
      setIsLoadingJobAd(false);
    }
  };

  // ------------------------------------------------------------------
  //  Add new questions manually on the right side
  // ------------------------------------------------------------------
  const addStaticQuestion = (type: "text" | "file") => {
    const newQuestion: InputElementQuestion = {
      id: Date.now(),
      text: "",
      order: localQuestions.length,
      type,
    };
    setLocalQuestions((prev) => [...prev, newQuestion]);
  };

  // Edit a question's text
  const handleInputChange = (id: number, value: string) => {
    setLocalQuestions((prev) =>
      prev.map((field) => (field.id === id ? { ...field, text: value } : field))
    );
  };

  // Remove a question
  const removeInputField = (id: number) => {
    setLocalQuestions((prevFields) =>
      prevFields
        .filter((field) => field.id !== id)
        .map((field, index) => ({ ...field, order: index }))
    );
  };

  // ------------------------------------------------------------------
  //  Single-container Drag and drop logic
  // ------------------------------------------------------------------
  // Helper for reordering
  const reorder = (
    list: InputElementQuestion[],
    startIndex: number,
    endIndex: number
  ): InputElementQuestion[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    // Update order
    return result.map((item, idx) => ({ ...item, order: idx }));
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return; // dropped outside

    // Reorder within the same single droppable
    if (source.droppableId === destination.droppableId) {
      const reordered = reorder(localQuestions, source.index, destination.index);
      setLocalQuestions(reordered);
    }
  };

  // ------------------------------------------------------------------
  //  Q&A Flow logic
  // ------------------------------------------------------------------
  const handleAskFollowUpQuestionsChange = (value: boolean) => {
    setSettingsLLM((prev) => ({ ...prev, askFollowUpQuestions: value }));
    resetInterviewFlow();
  };

  const handleTonalityChange = (value: number) => {
    setSettingsLLM((prev) => ({ ...prev, tonality: value }));
    resetInterviewFlow();
  };

  const handleAnswerSubmit = async () => {
    if (userAnswer.trim() !== "") {
      setIsSendingAnswer(true);

      const questionId = localQuestions[currentQuestionIndex].id;
      const currentQuestionText = isFollowUp
        ? followUpQuestion
        : localQuestions[currentQuestionIndex].text;

      const updatedSettingsLLM = {
        ...settingsLLM,
        askFollowUpQuestions: settingsLLM.askFollowUpQuestions && !isFollowUp,
      };

      const answerPayload = {
        questionId,
        question: currentQuestionText,
        answer: userAnswer.trim(),
        settingsLLM: updatedSettingsLLM,
      };

      try {
        const answerResponse = await fetch(`${Settings.API_URL}/interviews`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(answerPayload),
        });

        const answer = await answerResponse.json();

        let parsedEvaluation;
        try {
          parsedEvaluation = JSON.parse(answer.evaluationMessage);
        } catch (error) {
          console.error("Failed to parse evaluationMessage:", error);
          parsedEvaluation = { isAdequate: false, followUpQuestion: "" };
        }

        // Store user answer
        if (isFollowUp) {
          setQuestionsAnswers((prev) =>
            prev.map((qaItem, idx) => {
              if (idx === prev.length - 1) {
                return {
                  ...qaItem,
                  followUps: [
                    ...qaItem.followUps,
                    {
                      id: qaItem.followUps.length,
                      question: followUpQuestion,
                      answer: userAnswer.trim(),
                    },
                  ],
                };
              }
              return qaItem;
            })
          );
        } else {
          const newQuestionAnswer = {
            questionId,
            answer: userAnswer.trim(),
            followUps: [],
          };
          setQuestionsAnswers((prev) => [...prev, newQuestionAnswer]);
        }

        // Clear input
        setUserAnswer("");

        // Flow logic
        if (parsedEvaluation.isAdequate) {
          setIsFollowUp(false);
          if (currentQuestionIndex < localQuestions.length - 1) {
            setCurrentQuestionIndex((prev) => prev + 1);
          } else {
            setIsInterviewCompleted(true);
          }
        } else {
          // If answer is inadequate
          if (!isFollowUp && settingsLLM.askFollowUpQuestions) {
            // We haven't used follow-up yet → use it
            setIsFollowUp(true);
            setFollowUpQuestion(parsedEvaluation.followUpQuestion);
          } else {
            // Either no follow-ups left or they are disabled → go next
            setIsFollowUp(false);
            if (currentQuestionIndex < localQuestions.length - 1) {
              setCurrentQuestionIndex((prev) => prev + 1);
            } else {
              setIsInterviewCompleted(true);
            }
          }
        }
      } catch (error) {
        console.error("Failed to submit the answer:", error);
      } finally {
        setIsSendingAnswer(false);
        userAnswerRef.current?.focus();
      }
    }
  };

  const handleTagKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const trimmed = tagInput.trim();
      if (trimmed && !jobTags.includes(trimmed)) {
        setJobTags([...jobTags, trimmed]);
      }
      setTagInput("");
    }
  };

  const loadDashboard = () => {
    setQuestions(localQuestions);
    navigate("/demo-dashboard");
  };

  const resetInterviewFlow = () => {
    setCurrentQuestionIndex(0);
    setQuestionsAnswers([]);
    setIsInterviewCompleted(false);
    setIsFollowUp(false);
    setFollowUpQuestion("");
    setUserAnswer("");
  };

  return (
    <main className="flex-grow bg-[#f9f9f9]">

      <div className="text-center">
        <div className="mt-6 mb-4 flex justify-center items-center">
          <Link to="/" className="no-underline flex items-center mr-2">
            <Blend className="w-6 h-6 mr-2" color="#2563eb" />
            <span className="flex-none text-xl font-semibold text-[#235bd5] dark:text-white focus:outline-none focus:opacity-80">Worklens</span>
          </Link>
        </div>
        <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
          Setup your interview
        </h1>
      </div>

      <div className="relative overflow-hidden">
        <div className="max-w-[1100px] mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-16">
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="grid items-start md:grid-cols-2 gap-8 lg:gap-12">
              {/* Left Panel: Job Ad & Generate Suggestions */}
              <div className="px-6 py-4 min-h-[410px] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                <div>
                  <h2
                    className="mb-4 font-semibold text-gray-800 text-xl dark:text-white"
                  >
                    Create a job ad from keywords
                  </h2>
                  <div className="">
                    <input
                      type="text"
                      id="hs-feedback-post-comment-name-1"
                      className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 text-black"
                      placeholder="Keywords (e.g. 'Python' or 'Developer')"
                      value={tagInput}
                      onChange={(e) => setTagInput(e.target.value)}
                      onKeyDown={handleTagKeyDown}
                    />
                    <div className="flex flex-wrap gap-2 mt-3">
                      {jobTags.map((tag, idx) => (
                        <span
                          className="mb-4 inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium border border-gray-800 text-gray-800 dark:border-neutral-200 dark:text-white"
                          key={idx}
                        >
                          {tag}
                          <button
                            onClick={() => setJobTags((prevTags) => prevTags.filter((_, i) => i !== idx))}
                            type="button" className="shrink-0 size-4 inline-flex items-center justify-center rounded-full hover:bg-blue-200 focus:outline-none focus:bg-blue-200 focus:text-blue-500 dark:hover:bg-blue-900">
                            <svg className="shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M18 6 6 18"></path>
                              <path d="m6 6 12 12"></path>
                            </svg>
                          </button>
                        </span>
                      ))}
                    </div>
                    <div className="flex gap-4 items-end justify-between">
                      <button
                        type="button"
                        onClick={suggestJobAd}
                        disabled={isLoadingJobAd}
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-100 text-blue-800 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-400 dark:bg-blue-800/30 dark:hover:bg-blue-800/20 dark:focus:bg-blue-800/20 ml-auto"
                      >
                        {isLoadingJobAd ? "Fetching..." : "Generate job ad"}
                        <WandSparkles className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>

                {/* Editor & Generate Interview Questions */}
                <div>
                  <h2 className="mb-4 font-semibold text-gray-800 text-xl dark:text-white">
                    ...or enter your job ad here
                  </h2>
                  <div className="text-gray-800">
                      <DemoMarkdownEditor jobAd={jobAd} onUpdate={setJobAd} />
                    </div>
                    <div className="mt-6 flex gap-4 items-end justify-between">
                      <button
                        type="button"
                        onClick={suggestQuestions}
                        disabled={isLoadingSuggestions}
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-100 text-blue-800 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-400 dark:bg-blue-800/30 dark:hover:bg-blue-800/20 dark:focus:bg-blue-800/20 ml-auto"
                      >
                        {isLoadingSuggestions
                          ? "Fetching..."
                          : "Get question suggestions"}
                        <WandSparkles className="w-4 h-4" />
                      </button>
                    </div>
                </div>
              </div>

              {/* Right Panel: Draggable Questions OR Interview Flow */}
              <div className="px-6 py-4 min-h-[410px] flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                {!showInterview ? (
                  // --------------------
                  // (A) INTERVIEW QUESTIONS LIST (draggable)
                  // --------------------
                  <Droppable droppableId="droppable-questions">
                    {(provided) => (
                      <div
                        className="flex flex-col h-full max-h-[600px]"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <h2 className="mb-4 text-xl font-semibold text-gray-800 dark:text-white">
                          Interview questions
                        </h2>
                        <div className="mb-4 flex items-center">
                          <button
                            onClick={() => addStaticQuestion("text")}
                            type="button"
                            className="ml-auto py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700"
                          >
                            New question
                            <CirclePlus className="w-4 h-4" />
                          </button>
                        </div>

                        <div className="flex-grow mt-1 space-y-4 overflow-auto">
                          {localQuestions
                            .sort((a, b) => a.order - b.order)
                            .map((field, index) => (
                              <Draggable
                                key={field.id}
                                draggableId={field.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="flex items-center gap-2 p-2 border border-gray-200 dark:border-neutral-700 rounded-md bg-gray-50 dark:bg-neutral-900"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <textarea
                                      rows={2}
                                      className="bg-white dark:bg-neutral-800 py-2 px-3 w-full border border-gray-200 dark:border-neutral-700 rounded-lg text-sm text-black dark:text-neutral-200"
                                      value={field.text}
                                      onChange={(e) =>
                                        handleInputChange(field.id, e.target.value)
                                      }
                                      placeholder={
                                        field.type === "file"
                                          ? "E.g. Please attach your CV"
                                          : "Type your question here..."
                                      }
                                    />
                                    <button
                                      className="text-gray-400 hover:text-red-500"
                                      onClick={() => removeInputField(field.id)}
                                    >
                                      <CircleX className="w-5 h-5" />
                                    </button>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>

                        <div className="mt-8 flex gap-4 items-center justify-between">
                          <button
                            onClick={() => setShowInterview(true)}
                            type="button"
                            className={`py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-100 text-blue-800 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-400 dark:bg-blue-800/30 dark:hover:bg-blue-800/20 dark:focus:bg-blue-800/20 ${localQuestions.length === 0 ? 'hidden' : 'block'}`}
                          >
                            Try out the interview
                          </button>
                          <button
                            onClick={() => loadDashboard()}
                            type="button"
                            className={`py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ${localQuestions.length > 1 ? 'block' : 'hidden'}`}
                          >
                            Analyze candidates
                            <ArrowRight className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                    )}
                  </Droppable>
                ) : (
                  // --------------------
                  // (B) Q&A FLOW
                  // --------------------
                  <div className="flex flex-col h-full">
                    <div className="flex justify-between items-center w-full">
                      <h2 className="mb-4 font-semibold text-gray-800 dark:text-white">
                        Interview
                      </h2>
                      <button
                        onClick={() => {
                          setShowInterview(false);
                          resetInterviewFlow();
                        }}
                        className="text-sm text-gray-600 dark:text-gray-300 underline"
                      >
                        Back to questions
                      </button>
                    </div>
                    <div className="mb-4 hidden">
                      <label className="flex items-center space-x-2 text-sm text-gray-700 dark:text-neutral-300">
                        <input
                          type="checkbox"
                          checked={settingsLLM.askFollowUpQuestions}
                          onChange={() =>
                            handleAskFollowUpQuestionsChange(
                              !settingsLLM.askFollowUpQuestions
                            )
                          }
                          className="form-checkbox h-4 w-4"
                        />
                        <span className="flex items-center gap-1">
                          Ask for clarification if the answer is inadequate
                          <Info className="w-4 h-4 inline text-gray-400" />
                        </span>
                      </label>
                    </div>

                    <div className="mb-4 hidden">
                      <span className="block text-sm mb-1 text-gray-700 dark:text-neutral-300">
                        Tonality
                      </span>
                      <div className="space-x-4 text-sm">
                        <label className="inline-flex items-center space-x-1">
                          <input
                            type="radio"
                            name="tonality"
                            checked={settingsLLM.tonality === 1}
                            onChange={() => handleTonalityChange(1)}
                            className="form-radio h-4 w-4"
                          />
                          <span className="text-sm text-gray-500 dark:text-neutral-400">
                            Friendly
                          </span>
                        </label>
                        <label className="inline-flex items-center space-x-1">
                          <input
                            type="radio"
                            name="tonality"
                            checked={settingsLLM.tonality === 2}
                            onChange={() => handleTonalityChange(2)}
                            className="form-radio h-4 w-4"
                          />
                          <span className="text-sm text-gray-500 dark:text-neutral-400">
                            Formal
                          </span>
                        </label>
                      </div>
                    </div>

                    {/* Q&A Chat-like UI */}
                    <div className="space-y-6 mt-2 flex-grow overflow-auto">
                      <ul className="space-y-5">
                        {/* Already answered Qs */}
                        {questionsAnswers.map((qaItem, index) => {
                          const questionText =
                            localQuestions.find((q) => q.id === qaItem.questionId)?.text || "";
                          return (
                            <React.Fragment key={index}>
                              {/* Main question */}
                              <li className="max-w-lg flex gap-3 me-11">
                                <div className="inline-block rounded-full">
                                  <Bot className="h-8 w-8 text-black dark:text-white" />
                                </div>
                                <div className="bg-gray-100 dark:bg-neutral-900 border border-gray-200 dark:border-neutral-700 rounded-2xl p-3">
                                  <p className="text-sm text-gray-800 dark:text-gray-100">
                                    {questionText}
                                  </p>
                                </div>
                              </li>
                              {/* Main user answer */}
                              <li className="flex ms-auto gap-x-2 sm:gap-x-4">
                                <div className="grow text-end space-y-3">
                                  <div className="inline-block bg-blue-600 rounded-2xl p-3 shadow-sm">
                                    <p className="text-sm text-white">
                                      {qaItem.answer}
                                    </p>
                                  </div>
                                </div>
                                <span className="shrink-0 inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-600 text-white text-xs">
                                  You
                                </span>
                              </li>
                              {/* Follow-up Qs/As */}
                              {qaItem.followUps.map((fu) => (
                                <React.Fragment key={fu.id}>
                                  <li className="max-w-lg flex gap-3 me-11">
                                    <div className="inline-block rounded-full">
                                      <Bot className="h-8 w-8 text-black dark:text-white" />
                                    </div>
                                    <div className="bg-gray-100 dark:bg-neutral-900 border border-gray-200 dark:border-neutral-700 rounded-2xl p-3">
                                      <p className="text-sm text-gray-800 dark:text-gray-100">
                                        {fu.question}
                                      </p>
                                    </div>
                                  </li>
                                  <li className="flex ms-auto gap-3 items-start">
                                    <div className="grow text-end space-y-3">
                                      <div className="inline-block bg-blue-600 rounded-2xl p-3 shadow-sm">
                                        <p className="text-sm text-white">
                                          {fu.answer}
                                        </p>
                                      </div>
                                    </div>
                                    <span className="shrink-0 inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-600 text-white text-xs">
                                      You
                                    </span>
                                  </li>
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          );
                        })}

                        {/* Current question (if any remain) */}
                        {!isInterviewCompleted &&
                          currentQuestionIndex < localQuestions.length && (
                            <>
                              <li className="max-w-lg flex gap-3 me-11">
                                <div className="inline-block rounded-full">
                                  <Bot className="h-8 w-8 text-black dark:text-white" />
                                </div>
                                <div className="bg-gray-100 dark:bg-neutral-900 border border-gray-200 dark:border-neutral-700 rounded-2xl p-3">
                                  <p className="text-sm text-gray-800 dark:text-gray-100">
                                    {isFollowUp
                                      ? followUpQuestion
                                      : localQuestions[currentQuestionIndex]?.text}
                                  </p>
                                </div>
                              </li>

                              {/* User input box */}
                              <li className="relative mt-6">
                                <textarea
                                  ref={userAnswerRef}
                                  className="p-4 pb-20 block w-full bg-gray-100 border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-700 text-black dark:text-white dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                  value={userAnswer}
                                  onChange={(e) => setUserAnswer(e.target.value)}
                                  placeholder="Your answer here..."
                                />
                                <button
                                  type="button"
                                  onClick={handleAnswerSubmit}
                                  className="absolute right-3 bottom-3 w-10 h-10 flex items-center justify-center rounded-full bg-blue-600 text-white hover:bg-blue-500"
                                >
                                  {isSendingAnswer ? (
                                    <div className="animate-spin w-5 h-5 border-2 border-white border-t-transparent rounded-full" />
                                  ) : (
                                    <Send className="w-4 h-4" />
                                  )}
                                </button>
                              </li>
                            </>
                          )}
                      </ul>

                      {isInterviewCompleted && (
                        <div className="mt-4 text-sm text-gray-700 dark:text-gray-200">
                          <p>All questions answered! Thanks for trying out the demo.</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
      <Footer />

    </main>
  );
};

export default DemoCreateInterview;
