import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactQuill from "react-quill";
import { marked } from "marked";
import TurndownService from "turndown";
import "react-quill/dist/quill.snow.css";

const turndownService = new TurndownService();

interface JobAdEditorProps {
  jobAd: string;
  onUpdate: (markdown: string) => void;
}

const JobAdEditor: React.FC<JobAdEditorProps> = ({ jobAd, onUpdate }) => {
  const [editorContent, setEditorContent] = useState("");
  const quillRef = useRef<ReactQuill>(null);
  const isInitialMount = useRef(true);

  const markdownToHtml = useCallback(async (markdown: string): Promise<string> => {
    const html = await marked.parse(markdown);
    return html;
  }, []);

  useEffect(() => {
    const convertMarkdown = async () => {
      const html = await markdownToHtml(jobAd);
      setEditorContent(html);
    };

    if (isInitialMount.current || jobAd !== turndownService.turndown(editorContent)) {
      convertMarkdown();
    }
    isInitialMount.current = false;
  }, [jobAd, markdownToHtml, editorContent]);

  const handleChange = useCallback(
    (html: string, delta: any, source: string) => {
      const quill = quillRef.current?.getEditor();
      let newSelection: { index: number; length: number; } | null = null;

      // Get the current selection before the update
      const currentSelection = quill?.getSelection();
      
      // Check if the change was a line break
      if (source === 'user' && delta?.ops?.some(op => op.insert === '\n')) {
        // If it was a line break, move the cursor to the next line
        newSelection = {
          index: (currentSelection?.index || 0) + 1,
          length: 0
        };
      } else if (currentSelection) {
        // For other changes, maintain the current cursor position
        newSelection = {
          index: currentSelection.index + (delta?.ops?.[0]?.insert?.length || 0),
          length: 0
        };
      }

      setEditorContent(html);

      // Convert HTML → Markdown
      const md = turndownService.turndown(html);
      onUpdate(md);

      // Restore cursor position after state update
      if (newSelection && quill) {
        setTimeout(() => {
          quill.setSelection(newSelection);
        }, 0);
      }
    },
    [onUpdate]
  );

  const quillModules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ header: [1, 2, false] }],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  return (
    <ReactQuill
      ref={quillRef}
      value={editorContent}
      onChange={handleChange}
      modules={quillModules}
      placeholder="Write or paste your job ad here..."
      className="max-h-[500px] overflow-auto h-[320px]"
      style={{ borderBottom: "1px solid #ccc" }}
    />
  );
};

export default JobAdEditor;