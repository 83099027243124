import React, { useMemo, useState, useEffect, useContext } from "react";
import Footer from "./../components/Footer";
import { Tooltip } from "react-tooltip";
import { Blend, Check, Info, NotebookText, Search } from "lucide-react";
import Settings from "./../Settings";
import ReactMarkdown from "react-markdown";
import ModalDemoSynch from "./components/ModalDemoSynch";
import ModalDemoInterview from "./components/ModalDemoInterview";
import { QuestionsContext } from "./DemoContextProvider";
import { Link } from "react-router-dom";


export type Interview = { question: string; answer: string }[];

const DemoDashboard: React.FC = () => {
  const [isModalSynchOpened, setIsModalSynchOpened] = useState<boolean>(false);
  const closeModalSynch = () => setIsModalSynchOpened(false);

  const [isModalInterviewOpened, setIsModalInterviewOpened] =
    useState<boolean>(false);
  const closeModalInterview = () => setIsModalInterviewOpened(false);

  const { questions } = useContext(QuestionsContext);

  // If you only need the strings:
  const questionTexts = questions.map((q) => q.text);

  const [interviewQuestions] =
    useState<string[]>(questionTexts);

  const [currentInterview, setCurrentInterview] = useState<Interview | null>(null);

  // 1) Move textData into state so we can update it after the fetch
  const [textData, setTextData] = useState(() => ({
    metricOneLabel: "Interview visits",
    metricOneTooltip: "Total views of interview",
    metricOneCount: "432",
    metricOneDiff: "1.7%",
    metricTwoLabel: "Applicants",
    metricTwoCount: "108",
    metricThreeLabel: "Interview Completion Rate",
    metricThreeCount: "79.3%",
    metricThreeDiff: "1.7%",
    tableTitle: "Applicants",
    colName: "Name",
    colSummary: "Interview summary",
    colViewInterview: "View interview",
    colVerified: "Verified on LinkedIn",
    colKeywords: "Keywords",
    colRating: "Rating",
    colApplied: "Applied",
    rows: [
      {
        photo:
          "https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "Christina Bersh",
        email: "christina@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "28 Dec, 12:12",
        verified: false,
        interview: null
      },
      {
        photo:
          "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "David Harrison",
        email: "david@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "20 Dec, 09:27",
        verified: true,
        interview: null
      },
      {
        photo:
          "https://images.unsplash.com/photo-1597113113991-74221fab863e?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "Andrew Richard",
        email: "andrew@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "18 Dec, 15:20",
        verified: true,
        interview: null
      },
      {
        photo:
          "https://images.unsplash.com/photo-1567921503097-79c293fdadd6?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "Samia Kartoon",
        email: "samia@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "18 Dec, 15:20",
        verified: true,
        interview: null
      },
      {
        photo:
          "https://images.unsplash.com/photo-1545996124-0501ebae84d0?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "Brian Halligan",
        email: "brian@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "11 Dec, 18:51",
        verified: true,
        interview: null
      },
      {
        photo:
          "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "Andy Clerk",
        email: "andy@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "28 Dec, 12:12",
        verified: true,
        interview: null
      },
      {
        photo:
          "https://plus.unsplash.com/premium_photo-1671656333511-1b43049ea1a0?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "Barbara Simpson",
        email: "barbara@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "20 Dec, 09:27",
        verified: false,
        interview: null
      },
      {
        photo:
          "https://images.unsplash.com/photo-1618835962148-cf177563c6c0?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "Camila Welters",
        email: "cwelt@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "18 Dec, 15:20",
        verified: true,
        interview: null
      },
      {
        photo:
          "https://images.unsplash.com/photo-1618835962148-cf177563c6c0?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "Oliver Schevich",
        email: "oliver@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "20 Dec, 09:27",
        verified: false,
        interview: null
      },
      {
        photo:
          "https://images.unsplash.com/photo-1545167622-3a6ac756afa4?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "Ian Ivy",
        email: "ian@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "18 Dec, 15:20",
        verified: true,
        interview: null
      },
      {
        photo:
          "https://images.unsplash.com/photo-1595956553066-fe24a8c33395?ixlib=rb-4.0.3&auto=format&fit=facearea&w=320&h=320&q=80",
        name: "Jason Williams",
        email: "jwilliams@site.com",
        summary: null,
        keyword1: null,
        keyword2: null,
        rating: null,
        rationale: null,
        applied: "18 Dec, 15:20",
        verified: true,
        interview: null
      }
    ]
  }));

  const [prompt, setPrompt] = useState("");
  const [queryAnswer, setQueryAnswer] = useState({ answer: "" });
  const [isLoadingQueryAnswer, setIsLoadingQueryAnswer] = useState(false);

  // Sorting states
  const [sortBy, setSortBy] = useState<"name" | "rating">("name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  // 2) On first render, fetch the suggested data and populate textData.rows
  useEffect(() => {
    const fetchAndPopulateRows = async () => {
      try {
        // 1) Call your /demo/suggest endpoint
        const response = await fetch(`${Settings.API_URL}/demo/suggest`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            promptId: 2,
            questions: interviewQuestions.map((text, index) => ({ text, index }))
          })
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // 2) Parse the returned JSON array (11 objects)
        const suggestedData = await response.json();

        // 3) Merge each object into the corresponding row by index
        setTextData((prev) => {
          const newRows = prev.rows.map((row, i) => {
            const backend = suggestedData[i];
            if (!backend) return row; // Defensive check in case indexes mismatch

            return {
              ...row,
              summary: backend.summary,
              keyword1: backend.keyword1,
              keyword2: backend.keyword2,
              rating: backend.rating,
              rationale: backend.rationale,
              // Convert from an array of strings to an array of {question, answer}
              interview: (backend.answers || []).map((answer, aIdx) => ({
                question: interviewQuestions[aIdx],
                answer
              }))
            };
          });

          return { ...prev, rows: newRows };
        });
      } catch (error) {
        console.error("Error fetching suggested data:", error);
      }
    };

    fetchAndPopulateRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Helper to handle sorting
  const handleSort = (column: "name" | "rating") => {
    if (sortBy === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortDirection("asc");
    }
  };

  // Generate the rows for the current industry (sorted)
  const rows = useMemo(() => {
    const arr = [...textData.rows];

    if (sortBy === "name") {
      arr.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortBy === "rating") {
      // parseInt can fail if rating is null or non-numeric, so handle gracefully
      arr.sort(
        (a, b) =>
          parseInt(a.rating || "0", 10) - parseInt(b.rating || "0", 10)
      );
    }
    if (sortDirection === "desc") {
      arr.reverse();
    }
    return arr;
  }, [textData, sortBy, sortDirection]);

  // Example of streaming data from the server
  const askWorklens = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setIsLoadingQueryAnswer(true);

      // candidateData is the 'rows' array you already compute
      const candidateData = rows;
      const body = JSON.stringify({ candidateData, prompt });

      const response = await fetch(`${Settings.API_URL}/demo/query`, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain"
        },
        body
      });

      if (!response.ok || !response.body) {
        throw new Error("Network response was not ok");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      // Clear any existing text
      setQueryAnswer({ answer: "" });

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });

        // Append streamed chunk to state
        setQueryAnswer((prev) => ({
          answer: prev.answer + chunk
        }));
      }
    } catch (error) {
      console.error("Error during streaming:", error);
    } finally {
      setIsLoadingQueryAnswer(false);
    }
  };

  const text = textData;

  return (
    <main className="flex-grow bg-[#f9f9f9]">

      <div className="text-center">
        <div className="mt-6 mb-4 flex justify-center items-center">
          <Link to="/" className="no-underline flex items-center mr-2">
            <Blend className="w-6 h-6 mr-2" color="#2563eb" />
            <span className="flex-none text-xl font-semibold text-[#235bd5] dark:text-white focus:outline-none focus:opacity-80">Worklens</span>
          </Link>
        </div>
        <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl dark:text-white">
          Demo Dashboard
        </h1>
      </div>

      <div className="relative overflow-hidden">
        <div className="max-w-[1100px] mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-16">
          <ModalDemoSynch isOpened={isModalSynchOpened} onClose={closeModalSynch} />
          <ModalDemoInterview
            isOpened={isModalInterviewOpened}
            onClose={closeModalInterview}
            interview={currentInterview ?? []}
          />
          <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
            {/* Metric Cards */}
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-6">
              {/* Card 1 */}
              <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                <div className="p-4 md:p-5">
                  <div className="flex items-center gap-x-2">
                    <p className="text-xs uppercase tracking-wide text-gray-500 dark:text-neutral-500">
                      {text.metricOneLabel}
                    </p>
                    <div className="hs-tooltip">
                      <div className="hs-tooltip-toggle">
                        <svg
                          className="shrink-0 size-4 text-gray-500 dark:text-neutral-500"
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx={12} cy={12} r={10} />
                          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                          <path d="M12 17h.01" />
                        </svg>
                        <span
                          className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
                          role="tooltip"
                        >
                          {text.metricOneTooltip}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2">
                    <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                      {text.metricOneCount}
                    </h3>
                    <span className="flex items-center gap-x-1 text-green-600">
                      <svg
                        className="inline-block size-4 self-center"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="22 7 13.5 15.5 8.5 10.5 2 17" />
                        <polyline points="16 7 22 7 22 13" />
                      </svg>
                      <span className="inline-block text-sm">
                        {text.metricOneDiff}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* End Card 1 */}

              {/* Card 2 */}
              <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                <div className="p-4 md:p-5">
                  <div className="flex items-center gap-x-2">
                    <p className="text-xs uppercase tracking-wide text-gray-500 dark:text-neutral-500">
                      {text.metricTwoLabel}
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2">
                    <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                      {text.metricTwoCount}
                    </h3>
                  </div>
                </div>
              </div>
              {/* End Card 2 */}

              {/* Card 3 */}
              <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                <div className="p-4 md:p-5">
                  <div className="flex items-center gap-x-2">
                    <p className="text-xs uppercase tracking-wide text-gray-500 dark:text-neutral-500">
                      {text.metricThreeLabel}
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2">
                    <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                      {text.metricThreeCount}
                    </h3>
                    <span className="flex items-center gap-x-1 text-red-600">
                      <svg
                        className="inline-block size-4 self-center"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="22 17 13.5 8.5 8.5 13.5 2 7" />
                        <polyline points="16 17 22 17 22 11" />
                      </svg>
                      <span className="inline-block text-sm">
                        {text.metricThreeDiff}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {/* End Card 3 */}
            </div>
            {/* End Grid of Metric Cards */}

            {/* Search field example */}
            <div className="flex items-center w-full">
              {/* Search Form (Left-Aligned) */}
              <form
                onSubmit={askWorklens}
                className="flex items-center rounded-lg shadow-sm flex-none relative"
              >
                <span className="absolute flex size-3 left-0 top-0">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
                  <span className="relative inline-flex size-3 rounded-full bg-sky-500"></span>
                </span>
                <input
                  type="text"
                  placeholder="Ask anything about the interview & applicants"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  className="py-2 px-4 min-w-[340px] block border border-gray-200 shadow-sm rounded-s-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-gray-500 dark:text-neutral-400 flex-shrink-0 flex-grow-0"
                />
                <button
                  type="submit"
                  className="h-[40px] w-[2.875rem] shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-blue-600 text-white"
                >
                  {isLoadingQueryAnswer ? (
                    <div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full"></div>
                  ) : (
                    <Search className="w-4 h-4 shrink-0" />
                  )}
                </button>
              </form>

              {/* Export Button (Aligned to the Right) */}
              <button
                type="button"
                className="ml-auto h-[40px] px-4 flex items-center justify-center text-sm font-semibold rounded-lg border border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-100"
                onClick={() => setIsModalSynchOpened(!isModalSynchOpened)}
              >
                Synch candidates with ATS
              </button>
            </div>

            <p className="text-gray-500 dark:text-neutral-400 max-w-[620px] flex-grow">
              {queryAnswer.answer && (
                <ReactMarkdown className="text-sm prose dark:prose-invert">
                  {queryAnswer.answer}
                </ReactMarkdown>
              )}
            </p>
            {/* End Search field example */}

            {/* Card with Table */}
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">
                    {/* Header */}
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                      <div>
                        <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                          {text.tableTitle}
                        </h2>
                      </div>
                    </div>
                    {/* End Header */}

                    {/* Table */}
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                      <thead className="bg-gray-50 dark:bg-neutral-800">
                        <tr>
                          {/* Name col - sortable */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-start cursor-pointer"
                            onClick={() => handleSort("name")}
                          >
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                {text.colName}
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                {text.colSummary}
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-start relative">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                {text.colViewInterview}
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                {text.colVerified}
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                {text.colKeywords}
                              </span>
                            </div>
                          </th>
                          {/* Rating col - sortable */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-start cursor-pointer"
                            onClick={() => handleSort("rating")}
                          >
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                {text.colRating}
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                {text.colApplied}
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                        {rows.map((row: any, idx) => (
                          <tr key={idx}>
                            <td className="whitespace-nowrap">
                              <div className="px-6 py-3 text-start">
                                <div className="flex items-center gap-x-3">
                                  <img
                                    className="inline-block h-9 w-9 rounded-full"
                                    src={row.photo}
                                    alt="Avatar"
                                  />
                                  <div className="grow">
                                    <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                                      {row.name}
                                    </span>
                                    <span className="block text-sm text-gray-500 dark:text-neutral-500">
                                      {row.email}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="px-6 py-3">
                                <p className="block text-sm text-gray-500 dark:text-neutral-500">
                                  {row.summary}
                                </p>
                              </div>
                            </td>
                            <td className="relative">
                              <div className="px-6 py-3">
                                <p className="text-sm text-gray-800 dark:text-neutral-200 relative">
                                  {idx === 0 && (
                                    <span className="absolute flex size-2 left-0 top-0">
                                      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-pink-400 opacity-75"></span>
                                      <span className="relative inline-flex size-2 rounded-full bg-pink-500"></span>
                                    </span>
                                  )}
                                  <a
                                    className="flex items-center justify-center h-7 w-7 rounded-full bg-blue-600"
                                    onClick={() => {
                                      setCurrentInterview(row.interview);
                                      setIsModalInterviewOpened(true);
                                    }}
                                  >
                                    <NotebookText className="h-4 w-4 cursor-pointer text-white" />
                                  </a>
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="px-6 py-3">
                                <p className="text-sm text-gray-800 dark:text-neutral-200 text-center">
                                  {row.verified ? <Check className="w-4 h-4" /> : <></>}
                                </p>
                              </div>
                            </td>
                            <td className="whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="capitalize mb-2 mr-2 py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                                  {row.keyword1}
                                </span>
                                <br />
                                <span className="capitalize mb-2 py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                                  {row.keyword2}
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-3 text-start align-center">
                              <div className="">
                                <div className="">
                                  {row.rationale && (
                                    <span
                                      className="cursor-pointer block text-sm text-gray-800 dark:text-neutral-200 flex items-center"
                                      title={row.rationale}>
                                      {row.rating}&nbsp;
                                      <Info className="w-4 h-4 tooltip text-gray-400" data-tooltip-id="tooltip-rating-rationale" data-tooltip-content={row.rationale} />
                                      <Tooltip className="max-w-[200px]" id="tooltip-rating-rationale" style={{ zIndex: 99 }} />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500 dark:text-neutral-500">
                                  {row.applied}
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* End Table */}
                  </div>
                </div>
              </div>
            </div>
            {/* End Card with Table */}
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default DemoDashboard;
